import React from 'react'
import { HudInfo } from 'shared/ui/HudInfo/HudInfo'
import styles from './Hud.module.scss'
//import { WeaponSlots } from 'entities/ui/WeaponSlots/WeaponSlots'
import { HudNeeds } from 'entities/ui/HudNeeds/HudNeeds'
import { useHudInfoData } from 'shared/ui/HudInfo/model/useHudInfoData'
import { HudHeader } from 'shared/ui/HudHeader/HudHeader'
//import { ActionList } from 'shared/ui/ActionList/ActionList'
//import { DEFAULT_ACTION_LIST } from './Hud.config'
import Suppression from 'shared/ui/Suppression/Suppression'
import { AutoSensors } from 'entities/ui/AutoSensors/AutoSensors'
import { useAutoSensorsData } from 'entities/ui/AutoSensors/model/useAutoSensorsData'
import { useInteractionWheelData } from 'entities/ui/InteractionWheel/model/useInteractionWheelData'
import { InteractionWheel } from 'entities/ui/InteractionWheel/InteractionWheel'
import { setInteraction } from 'entities/ui/InteractionWheel/api/setInteraction'
// import HudWishes from 'widgets/ui/HudWishes/HudWishes'
import HudTickets from 'widgets/ui/HudTickets/HudTickets'
import HudHints from 'widgets/ui/HudHints/HudHints'
import { useStoreData } from 'widgets/ui/Store/model/useStoreData'
import Store from 'widgets/ui/Store/Store'
import { Chat } from 'shared/ui/Chat/Chat'
import GYMGame from 'widgets/ui/GYMGame/GYMGame'
import { useGYMGame } from 'widgets/ui/GYMGame/model/useGYMGame'
import { usePoliceRadar } from 'widgets/ui/PoliceRadar/model/usePoliceRadar'
import PoliceRadar from 'widgets/ui/PoliceRadar/PoliceRadar'
import HudPenaltyWidget from 'widgets/ui/HudPenaltyWidget/HudPenaltyWidget'

export const Hud = () => {
    const { data } = useHudInfoData()
    const { data: autoSensorsData, show: showSensors } = useAutoSensorsData()
    const { show: showInteractionWheel, type, params } = useInteractionWheelData()
    const { showStore, paramsStore } = useStoreData()
    const  GYMData  = useGYMGame()
    const policeRadarShow = usePoliceRadar()

    return (
        <div className={styles.container}>
            <Suppression />
            <div className={styles.hudInfo}>
                {data && <HudInfo {...data} />}
            </div>
            {/* <div className={styles.weaponSlots}>
                <WeaponSlots />
            </div> */}
            <div className={styles.needs}>
                <HudNeeds />
            </div>
            {showStore && paramsStore && <Store {...paramsStore} />}
            {/* <div className={styles.wishes}>
                <HudWishes />
            </div> */}

            {GYMData.start && <GYMGame duration={GYMData.duration}/>}

            <div className={styles.tickets}>
                <HudTickets />
            </div>
            <div className={styles.hints}>
                <HudHints />
            </div>
            <div className={styles.chat}>
                <Chat />
            </div>
            <div className={styles.header}>
                <HudHeader onlineText="544" id="B1 87" />
            </div>
            {/* <div className={styles.actionList}>
                <ActionList actions={DEFAULT_ACTION_LIST} />
            </div> */}
            {showSensors && (
                <div className={styles.autoSensors}>
                    <AutoSensors {...autoSensorsData} />
                </div>
            )}
            {showInteractionWheel && (
                <div className={styles.interactions}>
                    <InteractionWheel
                        type={type}
                        onClick={setInteraction}
                        params={params}
                    />
                </div>
            )}
            {policeRadarShow.isVisible && <PoliceRadar />}
            <HudPenaltyWidget />
        </div>
    )
}
