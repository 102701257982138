import React from 'react'
import { Input } from '../Input/Input'
import { Gap } from 'shared/ui/Gap/Gap'
import { PasswordInput } from '../PasswordInput/PasswordInput'
import { EMail, Login, PromoCode } from 'shared/icons/generated'
import { useFormik } from 'formik'
import styles from './RegistrationForm.module.scss'
import { CheckBox } from '../CheckBox/CheckBox'
import { SocialBlock } from '../SocialBlock/SocialBlock'
import { Button } from '../Button/Button'
import { useAuth } from 'app/context/AuthContext'
import { toastify } from 'shared/ui/Toast/Toast'
import * as Yup from 'yup'

const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .required('Необходимо указать логин')
        .min(6, 'Логин должен иметь от 6 до 20 символов')
        .max(20, 'Логин должен иметь от 6 до 20 символов')
        .lowercase('Логин должен быть в нижнем регистре')
        .matches(
            /^[a-z]+([-_]?[a-z0-9]+){0,2}$/i,
            'Логин содержит недопустимые символы'
        ),
    password: Yup.string()
        .required("Необходимо указать пароль")
        .min(6, 'Пароль должен иметь от 6 до 20 символов')
        .max(20, 'Пароль должен иметь от 6 до 20 символов')
        .matches(
            /^[0-9a-zA-Zа-яА-Я$&+,:;=?@#|'<>.-^*()%!]+$/,
            'Пароль содержит недопустимые символы'
        ),
    passwordRepeat: Yup.string().oneOf(
        [Yup.ref('password'), undefined],
        'Пароли должны быть одинаковыми'
    ).required("Повторите пароль"),
    email: Yup.string()
        .email('Некорректно указан e-mail')
        .required('Необходимо указать e-mail'),
})

interface RegistrationFormProps {
    onSuccess: () => void
}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({onSuccess}) => {
    const { signUp } = useAuth()
    const { handleChange, values, handleSubmit, setFieldValue, errors, touched } =
        useFormik({
            initialValues: {
                username: '',
                password: '',
                passwordRepeat: '',
                email: '',
                promocode: '',
                newsletter: true,
                acceptRules: false
            },
            validationSchema: SignupSchema,

            onSubmit: (values: any) => {
                if(!values.acceptRules) {
                    toastify.info("Необходимо принять правила сервера")
                    return
                }

                signUp(values).catch(() =>
                    toastify.error('Не удалось зарегистрироваться')
                ).then(() => {
                    onSuccess()
                    toastify.success("На вашу почту отправлено письмо с подтверждением")
                })
            },
        })

    return (
        <form className={styles.container} onSubmit={handleSubmit}>
            <SocialBlock type="registration" />
            <Gap size={16} />
            <Input
                Icon={Login}
                placeholder="Логин"
                onChange={handleChange}
                value={values.username}
                name="username"
                error={touched.username && errors.username}
            />
            <Gap size={10} />
            <PasswordInput
                onChange={handleChange}
                value={values.password}
                name="password"
                error={touched.password && errors.password}
            />
            <Gap size={10} />
            <PasswordInput
                onChange={handleChange}
                value={values.passwordRepeat}
                name="passwordRepeat"
                placeholder="Подтвердите пароль"
                error={touched.passwordRepeat && errors.passwordRepeat}
            />
            <Gap size={10} />
            <Input
                Icon={EMail}
                placeholder="E-mail"
                onChange={handleChange}
                value={values.email}
                name="email"
                error={touched.email && errors.email}
            />
            <Gap size={10} />
            <Input
                Icon={PromoCode}
                placeholder="Промокод"
                onChange={handleChange}
                value={values.promocode}
                name="promocode"
            />
            <Gap size={10} />
            <CheckBox
                label="Согласен принимать информационные рассылки"
                checked={values.newsletter}
                onChange={(checked) => setFieldValue('newsletter', checked)}
            />
            <Gap size={10} />
            <CheckBox label="Я ознакомился с правилами сервера и принимаю их"
            checked={values.acceptRules}
            onChange={(checked) => setFieldValue('acceptRules', checked)}
            />
            <Gap size={20} />
            <div className={styles.buttonsBlock}>
                <Button
                    value={'Правила сервера'}
                    className={styles.button}
                    full
                    variant="secondary"
                />
                <Button
                    value={'Создать аккаунт'}
                    type="submit"
                    className={styles.button}
                    full
                />
            </div>
        </form>
    )
}
