import React from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import { Routes, Route } from 'react-router-dom'
import ATM from 'widgets/ui/ATM/ATM'
import Store from 'widgets/ui/Store/Store'
import { previewStore } from '../widgets/ui/Store/__mocks__/preview'
import TattooShop from 'widgets/ui/TattooShop/TattooShop'
import { previewTattooShop } from 'widgets/ui/TattooShop/__mocks__/preview'
import { Hud } from 'pages/ui/Hud/Hud'
import { EventEmitter } from '../features/ui/EventEmitter/EventEmitter'
import { useRpcNavigation } from 'app/model/useRpcNavigation'
import { NavigationContext } from 'shared/context/context'
import DeathScreen from 'shared/ui/DeathScreen/DeathScreen'
import CriticalStateScreen from 'shared/ui/CriticalStateScreen/CriticalStateScreen'
import Helicam from 'widgets/ui/Helicam/Helicam'
import CharacterPersonalization from 'pages/ui/CharacterPersonalization/CharacterPersonalization'
import { Auth } from 'pages/ui/Auth/Auth'
import { AuthContext, useAuthContextData } from './context/AuthContext'
import { Toast } from 'shared/ui/Toast/Toast'

function App() {
    const params = useRpcNavigation()
    const authData = useAuthContextData()

    return (
        <NavigationContext.Provider value={params}>
            <AuthContext.Provider value={authData}>
                <Toast />
                <div
                    className={classNames(
                        styles.container,
                        window.alt?.isInjected && styles.grayBg
                    )}
                >
                    <EventEmitter />
                    {authData.isAuth ? (
                        <Routes>
                            <Route path="/" element={<Hud />} />
                            <Route path="/auth" element={<Auth />} />
                            <Route path="/atm" element={<ATM />} />
                            <Route
                                path="/store"
                                element={<Store {...previewStore} />}
                            />
                            <Route
                                path="/tattooShop"
                                element={<TattooShop {...previewTattooShop} />}
                            />
                            <Route
                                path="/deathScreen"
                                element={<DeathScreen />}
                            />
                            <Route
                                path="/criticalStateScreen"
                                element={<CriticalStateScreen />}
                            />
                            <Route path="/helicam" element={<Helicam />} />
                            <Route
                                path="/characterPersonalization"
                                element={<CharacterPersonalization />}
                            />
                        </Routes>
                    ) : (
                        <Auth />
                    )}
                </div>
            </AuthContext.Provider>
        </NavigationContext.Provider>
    )
}

export default App
